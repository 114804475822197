import {Controller} from "@hotwired/stimulus"
import {Jodit} from 'jodit'

export default class extends Controller {

    connect() {
        console.log(window.check_time)
            Jodit.make(this.element,
                {
                    imageProcessor: {
                        replaceDataURIToBlobIdInView: false
                    },
                    "buttons": "bold,underline,ul,ol,image,link,table,fontsize,left,center,right,source"
                }
            );
    }
}