import {Controller} from "@hotwired/stimulus"
import axios from "axios";

export default class extends Controller {

    connect() {
        const select = this.element.querySelector('select')
        select.onchange = () => this.change_article(this.element, select)
    }

    change_article(wrapper, input) {
        //console.log('input-value =>', input.value)

        if (input.value.split('-')[0] !== 'AccountingArticle') {
            //console.log('is not a article')
            return
        }


        const customer_id = this.element.getAttribute('data-customer-id')
        axios.get(`/customers/${customer_id}/accounting_articles/${input.value.split('-')[1]}/properties_json/`)
            .then(res => {
                const pr = wrapper.querySelector('#accounting_invoice_position_price_unit')
                const unit = wrapper.querySelector('#accounting_invoice_position_unit')
                const no = wrapper.querySelector('#accounting_invoice_position_quantity')
                const descr = wrapper.querySelector('.description')

                pr.value = res.data.price
                unit.value = res.data.unit
                no.value = res.data.quantity
                descr.value = res.data.description

                const wrapper_id = wrapper.id.split('-')[3]
                invoice_calc(wrapper_id)

            })
    }


}